import React, { Suspense } from "react";
import { Text } from "react-native";
import { ThemeProvider } from "./theme";
import {
  UserProvider,
  LoadingProvider,
  NotificationProvider,
  PostsProvider,
  LocationProvider,
} from "./providers";
import { SitesProvider } from "providers/SiteProvider";
import { OrganizationProvider } from "providers/OrganizationProvider";
import { ThreadsProvider } from "providers/ThreadProvider";
import { AsyncStorageLoader } from "providers/AsyncStorageLoader";

type Props = {
  children: JSX.Element;
};

const preloadKeys = [
  "pushtoken",
  "local--is-located-once",
  "local--location-data",
  "local--history-thanks-posts",
  "local--notification-settings",
  "local--followed-sites",
  "local--display-introduction",
  "local--display-cta-warning",
];

const AppProvider: React.FC<Props> = ({ children }: Props) => (
  <Suspense fallback={<Text />}>
    <AsyncStorageLoader preloadKeys={preloadKeys}>
      <ThemeProvider>
        <LoadingProvider>
          <NotificationProvider>
            <OrganizationProvider>
              <UserProvider>
                <LocationProvider>
                  <ThreadsProvider>
                    <SitesProvider>
                      <PostsProvider>{children}</PostsProvider>
                    </SitesProvider>
                  </ThreadsProvider>
                </LocationProvider>
              </UserProvider>
            </OrganizationProvider>
          </NotificationProvider>
        </LoadingProvider>
      </ThemeProvider>
    </AsyncStorageLoader>
  </Suspense>
);

export default AppProvider;
