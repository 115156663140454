import React, { createContext, useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const cacheData: any = {};

export const storeData = async (key: string, value: any) => {
  try {
    cacheData[key] = value;
    await AsyncStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error("Erreur de stockage :", e);
  }
};

export const getCachedData = (key: string) => {
  return cacheData[key];
};

export const getData = async (key: string) => {
  try {
    if (cacheData[key] !== undefined) {
      return cacheData[key];
    }
    const value = await AsyncStorage.getItem(key);
    return value != undefined ? JSON.parse(value) : value;
  } catch (e) {
    console.error("Erreur de récupération :", e);
  }
};

export const removeData = async (key: string) => {
  try {
    delete cacheData[key];
    await AsyncStorage.removeItem(key);
  } catch (e) {
    console.error("Erreur de récupération :", e);
  }
};

type Props = {
  preloadKeys: string[];
  children: React.ReactNode;
};

export const AsyncStorageLoader = ({ preloadKeys, children }: Props) => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    const get = async () => {
      await Promise.all(
        preloadKeys.map(async (key) => {
          const value = await AsyncStorage.getItem(key);
          // Si on ne trouve pas de cache ca retourne null
          // on stocke null dans le cache pour dire on a déjà testé le cache
          // mais il est vide
          if (value !== null) {
            cacheData[key] = JSON.parse(value);
          } else {
            cacheData[key] = null;
          }
        })
      );
      setIsReady(true);
    };
    get();
  }, [preloadKeys]);

  if (!isReady) {
    return null;
  }

  console.log("AsyncStorageLoader OK", cacheData);

  return children;
};
