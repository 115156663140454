import {
  getCachedData,
  getData,
  storeData,
} from "providers/AsyncStorageLoader";
import { useState, useEffect, useCallback } from "react";

const usePersistedState = (
  initialValue: any,
  key: string,
  nullable: boolean = false
) => {
  // On récupére la valeur du cache pour eviter le set asynchrone du a AsyncStorage
  const cachedData = getCachedData(key);

  // si le cacheData est vide ca veut dire que l'on a rien en cache
  // ou si cacheData = undefined (que la clé n'est pas caché)
  // dans les deux cas != null ca fonctionne
  const [storedValue, setStoredValue] = useState(
    cachedData != null ? cachedData : nullable ? null : initialValue
  );

  const valueIsArray = Array.isArray(nullable ? null : initialValue);

  useEffect(() => {
    const getStoredValue = async () => {
      try {
        let value = await getData(key);
        if (value !== null) {
          if (valueIsArray && !Array.isArray(value)) {
            value = [];
          }
          setStoredValue(value);
        }
      } catch (error) {
        // Handle error (e.g., unable to retrieve data from storage)
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    // On ne met à récupérer la valeur dans le storage que si y'a pas de cache
    const cachedData = getCachedData(key);
    // si cacheData === undefined ca veut dire que l'on a pas la clé en cache
    // donc il faut aller la chercher, si c'est cacheData = null
    // pas besoin d'y aller car on sait déjà que c'est vide
    // d'ou le test === undefined
    if (cachedData === undefined) {
      getStoredValue();
    }
  }, [/* initialValue, */ key, nullable, valueIsArray]);

  const setValue = useCallback(
    async (value: any) => {
      try {
        let v;
        setStoredValue(value);
        if (typeof value === "function") {
          v = value(storedValue);
        } else {
          v = value;
        }
        await storeData(key, v);
      } catch (error) {
        // Handle error (e.g., unable to store data in AsyncStorage)
        console.error("Error storing data in AsyncStorage:", error);
      }
    },
    [key, storedValue]
  );

  return [storedValue, setValue];
};

export default usePersistedState;
