import { createStackNavigator } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { IntroductionScreen } from "../components";
import { BottomTabNavigator } from "./BottomTabNavigator";
import { useLocation, useUser } from "hooks";
import { LoginMandatoryStackNavigator } from "./LoginMandatoryStackNavigator";
import * as SplashScreen from "expo-splash-screen";
import { Platform } from "react-native";
import { useTranslation } from "react-i18next";
import { LocationScreen } from "screens";
import useTheme from "theme/ThemeProvider";
import useConfiguration from "../configuration/ConfigurationProvider";

const ParamsStack = createStackNavigator();

export const RootStack: React.FC = () => {
  const { t } = useTranslation();
  const {
    displayIntroduction,
    user,
    isLoginMandatory,
    isUserAllowed,
    isUserReady,
  } = useUser();
  const { isLocatedOnce } = useLocation();
  const { configuration } = useConfiguration();

  const { theme } = useTheme();

  const WelcomeStack = createStackNavigator<any>();
  const LocationStack = createStackNavigator<any>();
  const showLoginStack = isLoginMandatory && (!user || !isUserAllowed());

  useEffect(() => {
    const checkIfCanRemoveSplashScreen = async () => {
      if (isUserReady && Platform.OS !== "web") {
        await SplashScreen.hideAsync();
      }
    };
    checkIfCanRemoveSplashScreen();
  }, [isUserReady]);

  if (!isUserReady) {
    return null;
  }

  return displayIntroduction !== false ? (
    <WelcomeStack.Navigator>
      <WelcomeStack.Screen
        name={"introduction"}
        component={IntroductionScreen}
        options={{
          headerShown: false,
          title: `${t("header.welcomeApp", {
            name: theme.texts.appName,
          })} | ${theme.texts.appName}`,
          headerTitle:
            t("header.welcomeApp", {
              name: theme.texts.appName,
            }) ?? "",
        }}
      />
    </WelcomeStack.Navigator>
  ) : !isLocatedOnce && configuration.sites.features.locationMandatory ? (
    <LocationStack.Navigator>
      <LocationStack.Screen
        name={"location"}
        component={LocationScreen}
        options={{
          headerShown: false,
          title: `${t("header.location")} | ${theme.texts.appName}`,
          headerTitle: t("header.location"),
        }}
      />
    </LocationStack.Navigator>
  ) : showLoginStack ? (
    <ParamsStack.Navigator>
      <ParamsStack.Screen
        name={"params"}
        component={LoginMandatoryStackNavigator}
        options={{
          headerShown: false,
        }}
      />
    </ParamsStack.Navigator>
  ) : (
    <BottomTabNavigator defaultHome="news" />
  );
};
